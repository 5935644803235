import React, { useState, useEffect, useRef } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '500px',
        maxHeight: '80vh',
        overflow: 'auto'
      }}>
        {children}
        <button onClick={onClose} style={{
          marginTop: '20px',
          padding: '10px 20px',
          backgroundColor: 'var(--secondary-gray-color)',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}>Close</button>
      </div>
    </div>
  );
};



function UploadForm() {

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fullName, setFullName] = useState('');
  const [title, setTitle] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [otherModel, setOtherModel] = useState('');
  const [magnification, setMagnification] = useState('40X');

  
  //console.log("Node env:", process.env.NODE_ENV);
  //console.log("env variables:", JSON.stringify(process.env));

/*  const storageAccountContainer = process.env.NODE_ENV === "production" ?
            process.env.REACT_APP_SA_CONTINER_PROD:
            process.env.REACT_APP_SA_CONTINER;*/  
  const storageAccountContainer = process.env.REACT_APP_SA_CONTINER; //'https://<your-storage-account-name>.blob.core.windows.net?<SAS-token>'
  const storageAccountSasAndUrl = process.env.REACT_APP_SA_SAS_AND_URL;            

  const providersAndModels = {
    'Olympus': ['BX43', 'BX53', 'CX23', 'CX33'],
    'Nikon': ['Eclipse E100', 'Eclipse Ci-L', 'Eclipse Ni-U'],
    'Zeiss': ['Axio Lab.A1', 'Axio Scope.A1', 'Primostar 3'],
    'Leica': ['DM500', 'DM750', 'DM1000'],
  };

  const [countriesTostates, setCountriesTostates] = useState({});
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedstate, setSelectedstate] = useState('');

  useEffect(() => {    
    const jsonUrl = '/data/countries.json';
    console.log('Attempting to fetch from:', jsonUrl);
    fetch(jsonUrl)
      .then(response => response.json())
      .then(data => {
        const countriesDict = {};
        data.forEach(country => {
          countriesDict[country.name] = country.states;
        });
        setCountriesTostates(countriesDict);
        setCountries(data.map(country => country.name));
      })
      .catch(error => console.error('Error loading countries data:', error));
  }, []);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setSelectedstate('');
  };

  const handlestateChange = (e) => {
    setSelectedstate(e.target.value);
  };

  const isSubmitDisabled = () => {
    return isLoading || !isDisclaimerChecked || !isEmailValid || !email || !selectedProvider ||  !magnification || !file || !fullName || !companyName || !title || !selectedCountry;
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleMagnificationChange = (e) => {
    setMagnification(e.target.value);
  };

  const handleProviderChange = (e) => {
    setSelectedProvider(e.target.value);
    setSelectedModel('');
    setOtherModel('');
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };
  const DisclaimerModal = ({ isOpen, onClose }) => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h3>Legal Disclaimer</h3>
      <ul style={{ paddingLeft: '20px', textAlign: 'start' }}>
        <li style={{ marginBottom: '10px' }}>You must have required permissions and consent to use and share with us the information for the purposes below.</li>
        <li style={{ marginBottom: '10px' }}>A valid email address is required to receive the report</li>
        <li style={{ marginBottom: '10px' }}>We will not share your email or video with third parties.</li>
        <li style={{ marginBottom: '10px' }}>The videos provided must not contain any identifying information regarding the patients.</li>
        <li style={{ marginBottom: '10px' }}>We may use the videos and email for internal research and development purposes.</li>
      </ul>
    </Modal>
  );

  const MessageDisplay = ({ message, type, onClose }) => {
    if (!message) return null;
  
    const style = {
      padding: '10px',
      marginTop: '10px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: type === 'success' ? 'green' : 'red',
      backgroundColor: type === 'success' ? '#e6ffe6' : '#ffe6e6',
    };
  
    return (
      <div style={style}>
        <span>{message}</span>
        <button onClick={onClose} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>✖</button>
      </div>
    );
  };

  
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploadMessage('');
    setMessageType('');
  };


  const resetForm = () => {
    setFile(null);
    setFullName('');
    setTitle('');
    setCompanyName('');
    setEmail('');
    setIsEmailValid(false);
    setSelectedCountry('');
    setSelectedstate('');
    setSelectedProvider('');
    setSelectedModel('');
    setOtherModel('');
    setMagnification('40X');
    setIsDisclaimerChecked(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    // Reset any other state variables you have for form fields
  };

  // Generate a random prefix
  const generateRandomPrefix = () => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  /*const validateMetadata = (metadata) =>{
    const metadataString = JSON.stringify(metadata);
    const base64Encoded = btoa(metadataString); // For browsers
    console.log("Base64 Encoded Metadata:", base64Encoded);


    const invalidKeyRegex = /[^a-zA-Z0-9-]/;
    const invalidValueRegex = /[^\x20-\x7E]/; // Non-ASCII or control characters
    for (const [key, value] of Object.entries(metadata)) {
        if (invalidKeyRegex.test(key)) {
            console.error(`Invalid key: ${key}`);
        }
        if (invalidValueRegex.test(value)) {
            console.error(`Invalid value for key "${key}": ${value}`);
        }
    }
}*/
  const encodeMetadata = (metadata) => {
    const encodedMetadata = {};
    for (const [key, value] of Object.entries(metadata)) {
        encodedMetadata[key] = encodeURIComponent(value);
    }

    return encodedMetadata;
  }



  const handleSubmit = async (e) => {
    e.preventDefault();    

    if (!isDisclaimerChecked) {
      setUploadMessage('Please agree to the legal disclaimer before uploading.');
      setMessageType('error');
      return;
    }
    
    setUploadMessage('');
    setMessageType('');
    setIsLoading(true);

    if (file) {
      //const microscopeType = e.target.microscopeType.value;
      let tags = {
        "fullName": fullName,
        "title": title,
        "companyName": companyName,
        "microscopeProvider": selectedProvider,
        "microscopeModel": selectedProvider === 'Other' ? otherModel : selectedModel,
        "magnification": magnification,
        "isdisclaimerChecked": isDisclaimerChecked ? "Yes" : "No",
        "email": email,
        "country": selectedCountry,
        "state": selectedstate
      };

      //validateMetadata(tags);
      tags = encodeMetadata(tags);


      try {
        await uploadFileWithTags(file, tags);
        setUploadMessage('Success! The video is in, and our AI is detecting sperms as we speak.');
        setMessageType('success');
        resetForm(); // Reset the form after successful upload

        // You can add further actions here, like showing a success message
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadMessage('Upload failed. Please try again.');
        setMessageType('error');
        // You can add error handling here, like showing an error message
      }

      setIsLoading(false);
    } else {
      console.log('No file selected');
    }
  };

  

  const uploadFileWithTags = async (file, tags) => {
  const blobServiceClient = new BlobServiceClient(storageAccountSasAndUrl);
  const containerClient = blobServiceClient.getContainerClient(storageAccountContainer);

  const randomPrefix = generateRandomPrefix();
  const blobName = `${randomPrefix}_${file.name}`;
  const blobClient = containerClient.getBlockBlobClient(blobName);


  

  // Upload the file and set tags in a single operation
  await blobClient.uploadData(file, {
    blobHTTPHeaders: { blobContentType: file.type },
    metadata: tags,
    onProgress: (progress) => {
      const percentComplete = Math.round((progress.loadedBytes / file.size) * 100);
      setUploadProgress(percentComplete);
    }
  });

  console.log("File uploaded and tags set successfully.");
  setUploadProgress(0); // Reset progress after upload
}

const ProgressBar = ({ progress }) => (
  <div style={{width: '100%'}}>
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'right'
  }}>
    <span>Upload Progress:</span>
  </div>
  <div style={{
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
    margin: '10px 0'
  }}>
    <div style={{
      width: `${progress}%`,
      backgroundColor: '#4CAF50',
      height: '20px',
      borderRadius: '5px',
      textAlign: 'center',
      lineHeight: '20px',
      color: 'white'
    }}>
      {progress}%
    </div>
  </div>
    <span>Upload may take a few minutes. Upload time depndent on video size and bandwidth.</span>
  </div>
);

   

  return (
    <form className="responsive-form" onSubmit={handleSubmit} style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',    
      gap: '20px',
      padding: '20px',      
      margin: '0 auto',      
      alignSelf: 'stretch',  // This tells the form to stretch to fill the container width
      flex: '1 1 auto',      // This allows the form to grow and shrink as needed
    }}>
      <div className="form-row" style={{        
        alignItems: 'self-start',      
        marginBottom: '10px',
        color: '#000000',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'start'
      }}>
        <div className="form-field">
        <label 
          htmlFor="fullName" 
          style={{            
            marginRight: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
          }}
        >
          Your Name*:
        </label>
        <input 
          type="text" 
          id="fullName" 
          name="fullName" 
          required 
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}          
            style={{
            width: '60%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc'
          }} />
          </div>
      </div>
      <div className="form-row" style={{        
        alignItems: 'self-start',      
        marginBottom: '10px',
        color: '#000000',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'start'
      }}>
        <div className="form-field">
        <label 
          htmlFor="title" 
          style={{            
            marginRight: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
          }}
        >
          Title*:
        </label>
        <select 
          id="title" 
          name="title" 
          required 
          value={title}
          onChange={(e) => setTitle(e.target.value)}          
          style={{
            width: '60%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc'
          }}
        >
          <option value="">Select Title</option>
          <option value="Lab Manager">Lab Manager</option>
          <option value="Medical Director">Medical Director</option>
          <option value="Nurse Practitioner">Nurse Practitioner</option>
          <option value="Founder & Chief Executive Officer">Founder & Chief Executive Officer</option>
          <option value="Laboratory Director">Laboratory Director</option>
          <option value="Other">Other</option>
        </select>
          </div>
      </div>
      <div className="form-row" style={{
  
  alignItems: 'self-start',  
  marginBottom: '10px',
  color: '#000000',
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'start'
}}>
  <div className="form-field">
  <label 
    htmlFor="companyName" 
    style={{
      marginRight: '10px',
      textAlign: 'left',
      fontWeight: 'bold'
    }}
  >
    Clinic Name*:
  </label>
  <input 
  type="text" 
    id="companyName" 
    name="companyName" 
    required     
    value={companyName}
    onChange={(e) => setCompanyName(e.target.value)}          
      style={{
      width: '60%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ccc'    
    }} />
    </div>
</div>
<div className="form-row">
        <div className="form-field" style={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
          marginBottom: '10px',
          color: '#000000',
          fontSize: '20px',
          fontWeight: 'bold',
        }}>
          <label 
            htmlFor="country" 
            style={{
              marginRight: '10px',
              textAlign: 'left',
              fontWeight: 'bold'
            }}
          >
            Country*:
          </label>      
          <select 
            id="country"
            name="country" 
            value={selectedCountry}
            onChange={handleCountryChange}
            required
            className="left-select-box"
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
          {selectedCountry && (
            <label 
              htmlFor="state" 
              className="state-label"
            >
              State or Province:
            </label>
          )}
          {selectedCountry && (
            <select 
              id="state"
              name="state" 
              value={selectedstate}
              className="right-select-box"
              onChange={handlestateChange}              
            >
              <option value="">Select state or province</option>
              {countriesTostates[selectedCountry] && countriesTostates[selectedCountry].map((state) => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          )}
        </div>
      </div>
    <div className="form-row" >
      <div className="form-field" style={{
        display: 'flex',
        alignItems: 'self-start',
        width: '100%',
        marginBottom: '10px',
        color: '#000000',
        fontSize: '20px',
        fontWeight: 'bold',
        textAlign: 'start'
      }}>
        <label 
          htmlFor="email" 
          style={{
            width: '30%',
            marginRight: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
          }}
        >
          Email*:
        </label>      
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'start', 
            gap: '10px', 
            width: '60%',            
          }}>
        <input 
          id="email"
          type="email" 
          name="email" 
          value={email} 
          onChange={handleEmailChange} 
          placeholder="Enter your email" 
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            backgroundColor: isEmailValid || !email ? 'white' : '#FFCDD2',
            color: isEmailValid || !email ? 'black' : 'red'
          }}
        />
        {!isEmailValid && email && <span style={{ color: 'red', fontSize: '14px' }}>Please enter a valid email address</span>}
      </div>
      </div>
      </div>
      <div className="form-row" >
      <div className="form-field" style={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: '10px',
        color: '#000000',
        fontSize: '20px',
        fontWeight: 'bold',
      }}>
        <label 
          htmlFor="microscopeProvider" 
          style={{        
            marginRight: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
          }}
        >
          Microscope Brand*:
        </label>      
        <select 
          id="microscopeProvider"
          name="microscopeProvider" 
          value={selectedProvider}
          onChange={handleProviderChange}
          required
          className="left-select-box"
        >
          <option value="">Select Provider</option>
          {Object.keys(providersAndModels).map((provider) => (
            <option key={provider} value={provider}>{provider}</option>
          ))}
          <option value="Other">Other</option>
        </select>
        {selectedProvider && (
  <label 
    htmlFor="microscopeModel" 
    className="state-label"
  >
    Model:
  </label>
)}
        {selectedProvider && selectedProvider !== 'Other' ? (
          <select 
            id="microscopeModel"
            name="microscopeModel" 
            value={selectedModel}
            onChange={handleModelChange}
            required
            className="right-select-box"
          >
            <option value="">Select Model</option>
            {providersAndModels[selectedProvider].map((model) => (
              <option key={model} value={model}>{model}</option>
            ))}
            <option value="Other">Other</option>
          </select>
        ) : selectedProvider === 'Other' ? (
          <input 
            id="microscopeModel"
            type="text" 
            name="microscopeModel" 
            value={otherModel}
            onChange={(e) => setOtherModel(e.target.value)}
            placeholder="Enter microscope model" 
            required
            style={{
              width: '20%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc'
            }}
          />
        ) : null}
         </div>
      </div>
      <div className="form-row" > 
      <div 
        className="form-field"
        style={{
          display: 'flex',
          alignItems: 'self-start',        
          marginBottom: '10px',
          color: '#000000',
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'start'
        }}>
        <label 
          htmlFor="magnification" 
          style={{
            width: '30%',
            marginRight: '10px',
            textAlign: 'left',
            fontWeight: 'bold'
          }}
        >
          Magnification*:
        </label>      
        <select 
          id="magnification"
          name="magnification" 
          value={magnification}
          onChange={handleMagnificationChange}
          required
          className="left-select-box"
        >         
          <option value="20X">20X</option>
          <option value="40X">40X</option>
          <option value="60X">60X</option>
          <option value="Other/Unknown">Other/Unknown</option>
        </select>
        </div>
      </div>
      <div className="form-row" >
      <div className="form-field" style={{ display: 'flex', alignItems: 'right', width: '100%' } }>
        <label 
            htmlFor="fileUpload" 
            style={{                    
              marginRight: '10px',
              textAlign: 'left',
              fontWeight: 'bold'              
            }}
          >
            Video File*:
          </label>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '10px', width: '60%' }}>
        <input 
          id="fileUpload"
          type="file" 
          onChange={handleFileChange}                
          ref={fileInputRef}
        />
        <div style={{ width:"fit-content", alignItems: 'left' }}>
          * MP4 or AVI | 1920x1080 | Preffered Magnification: 40x  
        </div>
        </div>
      </div>
    </div>
      
    <div className="form-row" >  
      <div className="form-field" style={{ display: 'flex', alignItems: 'left', width: '100%'}}>            
          <label htmlFor="legal_agreement" style={{ marginRight: '10px', textAlign: 'left', fontWeight: 'bold', width: '30%' }}>
            
        </label>
      <input type="checkbox" id="legal_agreement" name="legal_agreement" required 
      checked={isDisclaimerChecked}
      onChange={(e) => setIsDisclaimerChecked(e.target.checked)}
      style={{ width: 'fit-content' }}
      />
      <span style={{alignItems: 'left', width: '60%', textAlign: 'left', alignItems: 'center'}}>
      I agree to the terms and conditions of &nbsp;
      <a 
        href="#" 
        onClick={(e) => { 
          e.preventDefault(); 
          setIsDisclaimerModalOpen(true); 
        }}
        style={{ color: 'var(--secondary-gray-color)', textDecoration: 'underline' }}
      >
          legal disclaimer</a>:
        

      </span>
      </div>
      </div>
      <button 
        type="submit"
        disabled={isSubmitDisabled()}
        style={{
          padding: '10px 20px',
          backgroundColor:  isSubmitDisabled() ? '#808080' : 'var(--secondary-gray-color)',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
         {isLoading ? 'Uploading...' : 'Upload Video'}

      </button>
      
      {isLoading && <ProgressBar progress={uploadProgress} />}
      <MessageDisplay 
        message={uploadMessage} 
        type={messageType} 
        onClose={() => { setUploadMessage(''); setMessageType(''); }} 
      />
       <DisclaimerModal 
          isOpen={isDisclaimerModalOpen} 
          onClose={() => setIsDisclaimerModalOpen(false)}
        />
      </form>      
  );
}

export default UploadForm;